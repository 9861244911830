import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'product',
    redirect: '/home',
    component: () => import('../views/Product'),
    meta: { 
      title: '京卡物联'
    },
    children: [
      {
        path: '/mag',
        name: 'magdec',
        component: () => import('../views/products/MagProduct'),
        meta: { 
          title: '京卡物联-网络货运监管服务平台'
        },
      },
      {
        path: '/net',
        name: 'magdec',
        component: () => import('../views/products/NetProduct'),
        meta: { 
          title: '京卡物联-网络货运平台'
        },
      },
      {
        path: '/bobody',
        name: 'magdec',
        component: () => import('../views/products/NoBodyProduct'),
        meta: { 
          title: '京卡物联-厂矿无人职守管理系统'
        },
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/products/About'),
        meta: { 
          title: '京卡物联-关于我们'
        },
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home'),
    meta: { 
      title: '京卡物联'
    },
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
