import Vue from 'vue'
import App from './App.vue'
import { Carousel, CarouselItem, Form, FormItem, Button, Input } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import "./assets/style/reset.scss"
import "./util/index"

Vue.config.productionTip = false

Vue.use(Carousel);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(CarouselItem);
Vue.use(Input);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
